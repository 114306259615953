import React, { useState } from "react";
import { graphql, navigate, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import Layout from "../components/layout";
import CardProject from "../components/CardProject";
import ContactsBlock from "../components/ContactsBlock";

import imageWorkRemotely from "../images/photos/work-remotely.gif";
import imageWorkRemotelyWebp from "../images/photos/work-remotely.webp";

import Avatar1 from "../images/avatars/team/avatar1.png";
import Avatar2 from "../images/avatars/team/avatar2.png";
import Avatar3 from "../images/avatars/team/avatar3.png";
import Avatar4 from "../images/avatars/team/avatar4.png";
import Avatar5 from "../images/avatars/team/avatar5.png";
import Avatar6 from "../images/avatars/team/avatar6.png";
import Avatar7 from "../images/avatars/team/avatar7.png";
import Avatar8 from "../images/avatars/team/avatar8.png";
import Avatar9 from "../images/avatars/team/avatar9.png";
import Avatar10 from "../images/avatars/team/avatar10.png";
import Avatar12 from "../images/avatars/team/avatar12.png";

import IconHappy from "../images/icons/interface/happy-1.svg";
import IconConfused from "../images/icons/interface/confused.svg";
import IconSad from "../images/icons/interface/sad-1.svg";
import IconQuestion from "../images/icons/interface/question-smile.svg";
import IconLove from "../images/icons/interface/love-smile.svg";
import IconMissionUser from "../images/icons/pages/about-us/mission-user.svg";
import IconMissionStar from "../images/icons/pages/about-us/mission-star.svg";
import IconMissionCustomer from "../images/icons/pages/about-us/mission-customer.svg";
import IconMCameraProjects from "../images/icons/pages/about-us/camera-projects.svg";
import IconArrowRight from "../images/icons/pages/about-us/arrow-right.svg";
import IconCheck from "../images/icons/interface/check.svg";

import IconSamsung from "../images/logos/brand/grey-samsung.svg";
import IconHubspot from "../images/logos/brand/grey-hubspot.svg";
import IconRedBull from "../images/logos/brand/grey-red-bull.svg";
import IconTMobile from "../images/logos/brand/grey-t-mobile.svg";
import IconAioneersColored from "../images/logos/brand/aioneers-colored.svg";
import IconQoloColored from "../images/logos/brand/qolo-colored.svg";
import IconTMobileColored from "../images/logos/brand/t-mobile-colored.svg";
import IconSamsungColored from "../images/logos/brand/samsung-colored.svg";
import IconThinkPricingColored from "../images/logos/brand/think-pricing-colored.svg";
import IconKinteractColored from "../images/logos/brand/kinteract-colored.svg";

import SvgDivider1 from "../images/dividers/divider-1.svg";
import DecorationDots2 from "../images/decorations/deco-dots-2.svg";
import DecorationBlob4 from "../images/decorations/deco-blob-4.svg";
import DecorationLine4 from "../images/decorations/deco-lines-4.svg";

import useInViewPort from "../hooks/useInViewPort";

import { AboutUsJsonLd } from "../components/jsonld/AboutUsJsonLd";

const AboutUsPage = ({ data, location: { pathname } }) => {
  const [productTab, setProductTab] = useState(0);

  const productTabHandler = (tab) => {
    setProductTab(tab);
  };

  const [inViewRef, isInView] = useInViewPort();

  const projects = data.allDatoCmsProject.edges;
  const visionImg = data.visionImg.childImageSharp.gatsbyImageData;
  const missionImg = data.missionImg.childImageSharp.gatsbyImageData;
  const uxImg = data.uxImg.childImageSharp.gatsbyImageData;
  const uxImg2 = data.uxImg2.childImageSharp.gatsbyImageData;
  const valuesImg = data.valuesImg.childImageSharp.gatsbyImageData;

  return (
    <Layout meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
      <AboutUsJsonLd />

      <div className="about-us-page">
        <section className="bg-primary-alt has-divider first-section">
          <DecorationLine4 className="d-none d-lg-inline decoration-lines-4" />

          <div className="container pb-2">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-7 my-0 mx-auto text-center">
                <h1 className="display-4">We Are an Agile UX Design Agency</h1>

                <p className="lead m-0">
                  We’re a distributed and remote team of UX and UI Designers, UX
                  Researchers & Marketers.
                </p>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-flex justify-content-between align-items-center decoration-container">
            <div className="decoration-wrapper">
              <div className="decoration position-absolute scale-1">
                <DecorationDots2 className="bg-primary decoration-dots" />
              </div>

              <div className="decoration position-absolute scale-1">
                <DecorationBlob4 className="bg-primary-2 decoration-blob-4" />
              </div>
            </div>
          </div>

          <div className="divider">
            <SvgDivider1 />
          </div>
        </section>

        <section className="w-100 p-0 mt-0 pt-xl-4">
          <div className="container p-0">
            <div className="row d-none d-xl-flex align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                <h2 className="heading-two">Vision</h2>

                <p className="lead description m-0">
                  We execute and refine our partners' vision by cultivating a
                  strong design culture through educating, optimizing,
                  advocating proper design methods, and eliminating the
                  uncertainty from the ever-changing environment.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section p-0 mt-3">
          <div className="container p-0 mw-100">
            <div className="row mt-1 mt-xl-6">
              <div className="col-md-12 col-xl-6 pl-5 pr-5 pl-xl-0 pr-xl-0">
                <GatsbyImage
                  className="m-md-auto m-xl-0 vision-img"
                  loading={"lazy"}
                  image={visionImg}
                  alt="Vision"
                />
              </div>

              <div className="col-10 d-xl-none p-2 my-2 mx-auto">
                <h2 className="heading-two">Vision</h2>

                <p className="lead description m-0">
                  We execute and refine our partners' vision by cultivating a
                  strong design culture through educating, optimizing,
                  advocating proper design methods, and eliminating the
                  uncertainty from the ever-changing environment.
                </p>
              </div>

              <div className="col-md-12 col-xl-6 impacts-container">
                <div className="impact-wrapper">
                  <h3 className="impact-heading"> Business impact:</h3>
                  <ul className="list">
                    <li className="impact-benefit-list-item">
                      A Boost in Perceived Value
                    </li>
                    <li className="impact-benefit-list-item">
                      A Higher Retention
                    </li>
                    <li className="impact-benefit-list-item">
                      An Increase in Revenue
                    </li>
                  </ul>
                </div>
                <div className="impact-wrapper">
                  <h3 className="impact-heading"> Community impact:</h3>
                  <ul className="list">
                    <li className="alternate-list-item">
                      <span>
                        We've helped 250.000 people learn design (and counting)
                      </span>
                    </li>
                    <li className="alternate-list-item">
                      <span>
                        We've contributed to leading UX Resources like Smashing
                        Magazine, The Next Web and Web Designers Depot
                      </span>
                    </li>
                    <li className="alternate-list-item">
                      <span>
                        We published over a 100 articles and other resources
                        online on design
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-0 pt-2 pb-md-0 pt-md-6 third-section has-divider">
          <div className="container">
            <div className="row mt-2 mb-4 d-lg-none">
              <div className="col-12">
                <GatsbyImage
                  className="mission-img"
                  loading={"lazy"}
                  image={missionImg}
                  alt="Mission"
                />
              </div>
            </div>

            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-2 my-0 mx-auto text-lg-center">
                <h2 className="heading-two">Mission</h2>

                <p className="lead description m-0">
                  A world where each company dominates its niche and makes a
                  genuine impact on people’s life by integrating design in the
                  very companies' DNA.
                </p>
              </div>
            </div>

            <div className="row mt-2 mt-lg-4">
              <div className="col-md-12 col-lg-5 p-0">
                <div className="mission-list-container">
                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionUser
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">20M+</span>
                      <span className="mission-description">
                        user's lives impacted
                      </span>
                    </div>
                  </div>

                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionStar
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">~4.7 rating</span>
                      <span className="mission-description">
                        with our clients’ apps
                      </span>
                    </div>
                  </div>

                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionCustomer
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">4.9/5</span>
                      <span className="mission-description">
                        avg. customer satisfaction score
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 d-none d-md-inline">
                <GatsbyImage
                  className="mission-img"
                  loading={"lazy"}
                  image={missionImg}
                  alt="Mission"
                />
              </div>
            </div>
          </div>

          <div className="divider mission-divider mt-lg-4">
            <SvgDivider1 />
          </div>
        </section>

        <section className="fourth-section p-0">
          <div className="container p-2">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-4 mx-md-auto text-md-center">
                <h2 className="heading-two">What we Do</h2>
              </div>
            </div>
          </div>

          <div className="container mt-3 pl-4 p-md-0 swiper-buttons-container">
            <button
              className="d-none d-lg-inline-block swiper-buttons"
              onClick={() => productTabHandler(0)}
              style={{
                borderBottom: productTab === 1 ? "none" : "2px solid #2d63d7",
                color: productTab === 1 ? "#6C757D" : "#2d63d7",
              }}
            >
              Designing a New Product
            </button>
            <button
              className="d-none d-lg-inline-block swiper-buttons"
              onClick={() => productTabHandler(1)}
              style={{
                borderBottom: productTab === 0 ? "none" : "2px solid #2d63d7",
                color: productTab === 0 ? "#6C757D" : "#2d63d7",
              }}
            >
              Improving an Existing Product
            </button>
            <button
              className="d-lg-none swiper-buttons"
              onClick={() => productTabHandler(0)}
              style={{
                borderBottom: productTab === 1 ? "none" : "2px solid #2d63d7",
                color: productTab === 1 ? "#6C757D" : "#2d63d7",
              }}
            >
              MVP
            </button>
            <button
              className="d-lg-none swiper-buttons"
              onClick={() => productTabHandler(1)}
              style={{
                borderBottom: productTab === 0 ? "none" : "2px solid #2d63d7",
                color: productTab === 0 ? "#6C757D" : "#2d63d7",
              }}
            >
              Enhancement
            </button>
          </div>

          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={productTab === 0 ? 0 : 1}
            autoPlay={false}
            animationHandler="fade"
            swipeable={false}
            transitionTime={100}
          >
            <div>
              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">
                      We improved launched products
                    </h2>

                    <p className="swiper-description m-0">
                      We believe that improving a product is always better than
                      starting from scratch. That’s why we audit the app
                      first to identify all areas of improvement. Then, we
                      prioritize the issues, so that we can focus on the easy
                      and most significant wins first.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">Here's how it works:</h2>
                  </div>
                </div>
              </div>

              <div className="container mx-0 my-0 mw-100">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                    <GatsbyImage
                      className="ux-img"
                      loading={"lazy"}
                      image={uxImg}
                      alt="UX"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">
                      We embody your business in product design
                    </h2>

                    <p className="swiper-description m-0">
                      Don't Just Build a Product. We Create a Stunning User
                      Experience. At the end of the day, a beautiful, shiny app
                      could be a trap and not a milestone, fooling you into
                      thinking that looks or plenty of features are all that
                      matter.
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">Here's how it works:</h2>
                  </div>
                </div>
              </div>

              <div className="container mx-0 my-0 mw-100">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                    <GatsbyImage
                      className="ux-img"
                      loading={"lazy"}
                      image={uxImg2}
                      alt="UX"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Carousel>

          <div className="container mt-6 mb-3">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                <button
                  onClick={() => {
                    navigate("/ux-design-process");
                  }}
                  className="process-cta"
                >
                  See our Full Process Here
                </button>
              </div>
            </div>
          </div>

          <div className="divider swiper-divider bg-white">
            <SvgDivider1 />
          </div>
        </section>

        <section className="fifth-section text-white has-divider">
          <div className="container pb-0">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 ml-2 mx-md-auto text-md-center">
                <h2 className="products-heading">
                  A few products we designed:
                </h2>
              </div>
            </div>
          </div>

          <div className="container pt-0 ">
            <div className="row">
              {projects.map(({ node }) => (
                <div
                  className="col-lg-4 col-md-6 mb-0 p-0 project-card"
                  key={node.id}
                >
                  <CardProject cardData={node} />
                </div>
              ))}

              <div className="d-none d-lg-block col-lg-4 col-md-6 p-0">
                <div className=" card--case card--case--vertical">
                  <Link className="see-all-projects-card" to={`/ux-projects/`}>
                    <IconMCameraProjects
                      className="projects-camera"
                      alt="camera"
                    />
                    <p className="see-all-projects-description">
                      See all UX case studies
                    </p>
                    <IconArrowRight className="projects-camera" alt="camera" />
                  </Link>
                </div>
              </div>

              <div className="d-block d-lg-none col-lg-4 col-md-6 px-3">
                <Link to="/ux-projects" className="lead hover-arrow">
                  See All UX case studies
                </Link>
              </div>

            </div>
          </div>



          <div className="container pt-0">
            <div className="row pl-3 pr-3 p-lg-0">
              <h2 className="product-design-heading2">
                We don’t settle for mediocrity, neither should you
              </h2>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-6 m-0 pl-3 p-lg-0 content">
                <h3 className="product-design-heading3">
                  How founders normally approach design
                </h3>
              </div>
              <div className="col-md-6 col-lg-6 m-0 p-0 pl-7 content d-none d-lg-inline">
                <h3 className="product-design-heading3">
                  How product design should be approached
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-6 m-0 pl-3 pr-3 p-lg-0 content">
                <div>
                  <div className="process">
                    <div>
                      <IconHappy />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">
                        Finding a Jack of all Trades
                      </h4>
                      <p className="process-description">
                        You are on the hunt for a development team.{" "}
                      </p>
                      <p className="process-description">
                        You hire one that offers an all-inclusive package,
                        including UX and UI design for your app.
                      </p>
                      <p className="process-description mb-md-5">
                        Or you have gone for a freelancer who claims that they
                        can do everything (research, concept, strategy design,
                        and wording).
                      </p>
                    </article>
                  </div>

                  <div className="process">
                    <div>
                      <IconConfused />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">
                        Months of Intense Work
                      </h4>
                      <p className="process-description mb-md-5">
                        After months of intense work, your app is out and in the
                        market.{" "}
                        <strong>
                          But it’s not the earth-shattering success that you
                          hoped for.
                        </strong>
                      </p>
                    </article>
                  </div>

                  <div className="process">
                    <div>
                      <IconSad />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">Subpar Results </h4>
                      <p className="process-description mb-md-5">
                        Users aren’t adopting it as much as you thought. They’re
                        leaving your product.
                      </p>
                    </article>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mx-auto pl-3 pr-3 pl-lg-7 pr-lg-0 content">
                <div>
                  <div className="col-md-6 col-lg-6 m-0 p-0 pl-7 content d-inline d-lg-none">
                    <h3 className="product-design-heading3">
                      How product design should be approached
                    </h3>
                  </div>
                  <div className="process">
                    <div>
                      <IconQuestion />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">
                        Find a dedicated Product Design Agency
                      </h4>
                      <p className="process-description mb-md-5">
                        Product design should be anything but an afterthought.
                        What usually happens with full-ride agencies is that
                        they don't do justice to making sure your product is
                        useful, useable, and used. We're different. UX is at the
                        core of what we do.
                      </p>
                    </article>
                  </div>

                  <div className="process">
                    <div>
                      <IconHappy />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">
                        Stick to a methodical UX Process
                      </h4>
                      <p className="process-description mb-md-5">
                        Having the right people do the right things fail-proofs
                        your product to a large extent. A proper process is how
                        you quickly iterate, gather feedback and pivot your way
                        toward the product-market fit.
                      </p>
                    </article>
                  </div>

                  <div className="process">
                    <div>
                      <IconLove />
                    </div>
                    <article className="ml-3">
                      <h4 className="process-heading">Reap the Rewards</h4>
                      <p className="process-description mb-md-5">
                        After months of intense research, design, and iterations
                        you can see the results you could only hope for. This is
                        the magic of UX.
                      </p>
                    </article>
                  </div>

                  <div className="process">
                    <div className="icon-round icon-round-full icon-round-xs bg-primary process-icon-check">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <Link
                      to="/free-strategy-session"
                      id="freeconsultation"
                      className="ml-3 mt-0 product-cta lead hover-arrow"
                      data-aos="fade-up"
                    >
                      Let’s improve and scale your product
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-7">
              <div className="col-md-12 col-lg-7 pl-3 pr-3 p-lg-0">
                <h2 className="product-design-heading2">Our Team </h2>
                <ul className="list">
                  <li className="list-item">
                    Professional researchers with PhD’s;
                  </li>
                  <li className="list-item">
                    Only senior product designers (full-stack designers);
                  </li>
                  <li className="list-item">
                    Professionals with 6-8 hour overlap with EU and US time;
                  </li>
                  <li className="list-item">
                    Agile experts that are able to seamlessly adapt to your
                    internal processes.
                  </li>
                </ul>

                <div className="trusts-container">
                  <span className="trusts-heading">Trusted By </span>
                  <div className="partners-container mt-3 mb-4 mb-lg-0">
                    <IconSamsung
                      className="partner mb-4 mb-lg-0"
                      alt="samsung"
                    />
                    <IconTMobile
                      className="partner mb-4 mb-lg-0"
                      alt="t-mobile"
                    />
                    <IconHubspot
                      className="partner mb-4 mb-lg-0"
                      alt="hubspot"
                    />
                    <IconRedBull
                      className="partner mb-4 mb-lg-0"
                      alt="red-bull"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-5 text-center">
                <div className="team-wrapper m-auto d-inline d-lg-inline-flex">
                  
                  {/* Adam */}
                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Adam Fed */}
                  <img
                    src={Avatar2}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Anastasia */}
                  <img
                    src={Avatar3}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Anastasiya */}
                  <img
                    src={Avatar4}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Dima */}
                  <img
                    src={Avatar5}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Dragana */}
                  <img
                    src={Avatar12}
                    className="team-member"
                    alt="team-member"
                  />
                  
                  {/* Joe */}
                   {/* <img
                    src={Avatar10}
                    className="team-member"
                    alt="team-member"
                  /> */}

                  {/* Kasia */}
                  <img
                    src={Avatar6}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Nicole */}
                  <img
                    src={Avatar7}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Raoni */}
                  <img
                    src={Avatar8}
                    className="team-member"
                    alt="team-member"
                  />

                  {/* Stefan */}
                  <img
                    src={Avatar9}
                    className="team-member"
                    alt="team-member"
                  />
                  
                  {/* <img
                    src={Avatar11}
                    className="team-member"
                    alt="team-member"
                  /> */}
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section bg-primary-3 text-white has-divider">
          <div className="container">
            <div className="row top">
              <div className="col-md-6 pl-2 pl-md-0">
                <h3 className="h1 text-white">We Work Remotely</h3>
                <p className="lead">
                  We didn't turn remote overnight! We carefully adapted our own
                  design process for the remote environment.
                </p>
                <p className="lead">
                  <strong>
                    The result is a highly effective process ready for teams
                    willing to scale!
                  </strong>
                </p>
                <p className="lead">
                  We have remote design workshops, collaborative design
                  processes, and brainstorming sessions.
                </p>
                <p className="lead">
                  We don’t send emails or jump on Skype calls for the sake of
                  being in touch.{" "}
                </p>

                <Link
                  to="/free-strategy-session"
                  id="freeconsultation"
                  className="mt-3 btn btn-lg btn-primary"
                  data-aos="fade-up"
                >
                  Free Strategy Session
                </Link>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="image mt-6 mt-md-0 d-none d-md-block">
                  {/* gatsby-image doesn't support gifs, have to convert them manually :/ */}
                  <picture>
                    <source type="image/webp" srcSet={imageWorkRemotelyWebp} />
                    <source srcSet={imageWorkRemotely} type="image/gif" />
                    <img src={imageWorkRemotely} alt="" loading="lazy" />
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={inViewRef} className="seventh-section pb-0">
          <div className="container m-auto pt-lg-3 pb-3">
            <div className="row p-3 p-lg-0 text-lg-center">
              <p className="session-intro m-auto">
                After a session with us, you may even realize how inefficient
                and time consuming your old-fashioned face-to-face meetings are.
              </p>
            </div>

            <div className="row text-lg-center mt-3 mt-lg-6 mb-3 p-3 p-lg-0">
              <p className="session-intro m-auto">
                That’s because our values are:
              </p>
            </div>

            <div className="row mt-3 mt-lg-7">
              <div className="col-md-12 col-lg-6 p-0 d-none d-lg-inline">
                <GatsbyImage
                  className="values-img"
                  loading={"lazy"}
                  image={valuesImg}
                  alt="Values"
                />
              </div>
              <div className="col-md-12 col-lg-6">
                <ul className="values-list">
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">Be flexible</span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">
                        On the way to excellency, fail fast and often
                      </span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">Be an accountable partner</span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">
                        Be proactive, not just reactive
                      </span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">
                        Thrive together towards personal and professional
                        improvement
                      </span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">
                        Be creative in your problem-solving
                      </span>
                    </div>
                  </li>
                  <li className="values-list-item">
                    <div className="icon-round icon-round-full icon-round-xs bg-success">
                      <IconCheck className="icon value-icon bg-white" />
                    </div>
                    <div className="value-container">
                      <span className="value">Be effective and efficient </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="divider values-divider">
            <SvgDivider1 />
          </div>
        </section>

        <section className="eighth-section bg-primary-alt pb-0 pb-md-7">
          <div className="container pb-4">
            <div className="row text-center mb-7">
              <h2 className="work-heading">Who we work with</h2>
            </div>
            <div className="row work-row">
              <div className="col-lg-7">
                <article>
                  <h3> SMB's</h3>
                  <p>
                    We help our SMB clients both develop new features or revamp
                    their legacy design. Either way, we guarantee high usability
                    and an increase in your metrics.
                  </p>
                </article>
              </div>
              <div className="col-lg-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-around justify-content-md-start">
                <IconAioneersColored className="work-partner" />
                <IconQoloColored className="work-partner" />
              </div>
            </div>
            <div className="row work-row">
              <div className="col-lg-7">
                <article>
                  <h3> Enterprise Clients</h3>
                  <p>
                    Our Enterprise clients benefit most out of our ability to
                    multiply their design shipping capacity. We seamlessly
                    integrate into your current design workflow and provide
                    results beyond your expectations.
                  </p>
                </article>
              </div>
              <div className="col-lg-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-around justify-content-md-start">
                <IconTMobileColored className="work-partner" />
                <IconSamsungColored className="work-partner" />
              </div>
            </div>
            <div className="row work-row">
              <div className="col-lg-7">
                <article>
                  <h3> Startups: bootstrapped or funded</h3>
                  <p>
                    Whether you have a launched product or just working on your
                    MVP, we got you covered. Our methodical UX process ensures a
                    solid path to product-market fit and exceptional metrics.
                  </p>
                </article>
              </div>
              <div className="col-lg-5 pt-5 pt-lg-0 d-flex align-items-center justify-content-around justify-content-md-start">
                <IconThinkPricingColored className="work-partner" />
                <IconKinteractColored className="work-partner" />
              </div>
            </div>
          </div>
        </section>
      </div>

      <ContactsBlock isInViewProp={isInView} />
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query AboutUsPage {
    datoCmsPage(slug: { eq: "about-us" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
    visionImg: file(relativePath: { eq: "photos/about-us-vision.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    missionImg: file(relativePath: { eq: "photos/about-us-mission.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    uxImg: file(relativePath: { eq: "photos/about-us-ux.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    uxImg2: file(relativePath: { eq: "photos/about-us-ux-2.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    valuesImg: file(relativePath: { eq: "photos/about-us-values.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allDatoCmsProject(
      limit: 2
      filter: { hiddenProject: { ne: true }, featuredProject: { eq: true } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          cardImage {
            gatsbyImageData(width: 376, layout: CONSTRAINED)
          }
          logo {
            url
          }
          cardColor {
            hex
          }
          cardDescription
          categoryProject
          industry
          slug
          id
          secretProject
        }
      }
    }
  }
`;
